Object.defineProperty(exports, "__esModule", {
    value: true
  });

  // Customizable Area Start
  exports.drawerMenu = [{pageName:'Terms & Conditions',pagePath:'Termsandconditions'},{pageName:'Privacy Policy',pagePath:'PrivacyPolicy'},{pageName:"FAQ's",pagePath:'Settings2'},{pageName:'Guides & Information',pagePath:'Settings2'},{pageName:'Referral Program',pagePath:'Settings2'},{pageName:'Legal',pagePath:'Settings2'},{pageName:'Help',pagePath:'Settings2'}]
  exports.drawerMenuScreenManager = [{pageName:'Settings',pagePath:'ManagerSetting', noArrow: true},{pageName:'Help',pagePath:'ManagerHelp', noArrow: true}, {pageName:'Logout',pagePath:'Logout', noArrow: true}]

  exports.headerHelpTxt = "HELP";
  exports.headerSettingTxt = "SETTINGS";
  exports.headerChangePassword = "CHANGE PASSWORD";
  exports.headerChangeEmail = "CHANGE EMAIL";
  exports.headerUpdateProfile = "EDIT PROFILE";

  exports.validationApiContentType = "application/json";
  exports.logoutApiEndPoint = '/account_block/accounts/logout'
  exports.logoutApiMethod = 'DELETE'

  exports.logoutAlert={
    title:'Are you sure you want to log out of your account?',
    text:'',
    buttons:[
      {name:'Cancel',type:'text',action:'cancel',class:'textBtn'},
      {name:'Logout',type:'contained',action:'logout',class:'conatinedBtn'}
    ]
  }

  exports.httpPutMethod = "PUT";
  exports.httpPostMethod = "POST";
  exports.httpGetMethod = "GET";
  exports.httpDeleteMethod = "DELETE";

  exports.updateUserProfileApiEndPoint = '/account_block/screen_managers/update_profile'
  exports.getUserProfileApiEndPoint = '/account_block/accounts/get_profile'
  
  exports.getRegionApiEndPoint = '/bx_block_custom_form/screens/search_region'
  exports.addRegionApiEndPoint = '/account_block/screen_managers/add_region'

  exports.addScreenApiEndPoint = '/account_block/screen_managers/create_screen'
  exports.updateScreenApiEndPoint = '/account_block/screen_managers/update_screen'
  exports.getScreenApiEndPoint = '/bx_block_custom_form/screens/'
  
  exports.getAdDetailsApiEndPoint = '/bx_block_custom_form/bookings/file_details?file_id='
  exports.removeAdApiEndPoint = '/bx_block_custom_form/screens/delete_media'
  exports.getUsersAdListApiEndPoint = '/account_block/screen_managers/user_ad_details?user_id='

  exports.regionsListEndPoint = "bx_block_custom_form/screens/screen_regions";
  exports.regionScreensEndPoint = "/bx_block_custom_form/screens/filter_screens?";
  exports.recentScreensEndPoint = "bx_block_content_moderation/moderation/recent_search";
  exports.clearSearchHistory = "/account_block/screen_managers/clear_history";

  // Customizable Area End
import React from "react";

import { Box, Button, Container, Grid, Typography } from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import AdManagerController, { Props, DashboardStates } from "./AdManagerController.web";
import { Toolbar, Paper, Avatar, IconButton, Divider, styled, Popover } from "@material-ui/core";
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import AppHeader from '../../../components/src/AppHeader.web';

import { user, smallView, fullScreen, upload }  from './assets';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
const configJSON = require("./config");

interface DashboardTransaction {
  name: string;
  amount: number | null;
  date: string;
  location: string;
  profile_photo: string;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

export default class AdManager extends AdManagerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <HamburgerMenu 
          navigation={this.props.navigation} 
          fromPage="manager_home" 
          hideBottom={false}
        >
          <AppHeader navigation={this.props.navigation} />

          <Toolbar />
          <div style={webStyle.root}>

            <MenuClick data-test-id="region-menu" onClick={this.handleMenuOpen}>
              <Typography className="menuSelectTxt" style={{fontSize: '14px'}}>{ this.state.txtSavedValue || 'Last 7 Days'}</Typography>
              <ArrowDropDownIcon />
            </MenuClick>
            <StyledPopover
              data-test-id="popover-data"
              elevation={3}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              anchorEl={typeof this.state.number === 'string' ?  null : this.state.number }
              keepMounted
              open={Boolean(this.state.number)}
              onClose={this.handleMenuClose}
            >
              <ul className="regionsList">
                {
                  configJSON.filterList.filter((region: DashboardStates) => !region.key.includes(this.state.txtInputValue)).map((filterData: DashboardStates, index: number) => {
                    return (
                      <li 
                        data-test-id="region_item" 
                        key={`${filterData.key}-${index}`} 
                        className="regionsListItem" 
                        onClick={() => {
                          this.handleMenuClose();
                          this.selectFilter(filterData)
                        }}
                      >
                        {filterData.title}
                      </li>
                    )
                  })
                }
              </ul>
            </StyledPopover>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6}>
                <Paper style={{...webStyle.card, position: 'relative'}}>
                  <Grid container spacing={2} style={{marginBottom: '5px'}}>
                    <Grid item xs={8}>
                      <Typography variant="subtitle1" style={{...webStyle.subtitleTexts}}>Active</Typography>
                      <Typography variant="h5" style={{...webStyle.titleTexts}}>Screens</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <IconButton style={{...webStyle.iconButton}}>
                        <img style={{...webStyle.iconStyle, width: '20px', height: '20px'}} src={fullScreen} />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Typography variant="h4" style={{...webStyle.numberTexts}}>{this.getDashboardState('active_screen_count')}</Typography>
                  {this.getDashboardState('active_screen_count') ? <Typography style={{...webStyle.subtitleTexts, ...webStyle.increase}}>12% Increase</Typography> : ' '}
                </Paper>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Paper style={{...webStyle.card, position: 'relative'}}>
                 <Grid container spacing={2} style={{marginBottom: '5px'}}>
                    <Grid item xs={8}>
                      <Typography variant="subtitle1" style={{...webStyle.subtitleTexts}}>Inactive</Typography>
                      <Typography variant="h5" style={{...webStyle.titleTexts}}>Screens</Typography>
                    </Grid>
                    <Grid item xs={4}>

                      <IconButton style={{...webStyle.iconButton}}>
                        <img style={{...webStyle.iconStyle, width: '20px', height: '20px'}} src={smallView} />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Typography style={{...webStyle.numberTexts}} variant="h5" >{this.getDashboardState('inactive_screen_count')}</Typography>
                  {this.getDashboardState('inactive_screen_count') ? <Typography style={{...webStyle.increase, ...webStyle.subtitleTexts, }}>08% Decrease</Typography> : ' '}
                </Paper>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Paper style={{...webStyle.card, position: 'relative'}}>
                 <Grid container spacing={2} style={{marginBottom: '5px'}}>
                    <Grid item xs={8}>
                      <Typography variant="subtitle1" style={{...webStyle.subtitleTexts}}>New</Typography>
                      <Typography variant="h5" style={{...webStyle.titleTexts}}>Users</Typography>
                    </Grid>
                    <Grid item xs={4}>

                      <CustomIconButton>
                        <img style={{...webStyle.iconStyle, width: '28px', height: '28px'}} src={user} />
                      </CustomIconButton>
                    </Grid>
                  </Grid>
                  <Typography style={{...webStyle.numberTexts}} variant="h3" >{this.getDashboardState('new_users_count')}</Typography>
                  {this.getDashboardState('new_users_count') ? <Typography style={{...webStyle.subtitleTexts, ...webStyle.increase}}>12% Increase</Typography> : ' '}
                </Paper>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Paper style={{...webStyle.card, position: 'relative'}}>
                 <Grid container spacing={2} style={{marginBottom: '5px'}}>
                    <Grid item xs={8}>
                      <Typography variant="subtitle1" style={{...webStyle.subtitleTexts}}>New</Typography>
                      <Typography variant="h5" style={{...webStyle.titleTexts}}>Uploads</Typography>
                    </Grid>
                    <Grid item xs={4}>

                      <CustomIconButton>
                        <img style={{...webStyle.iconStyle}} src={upload} />
                      </CustomIconButton>
                    </Grid>
                  </Grid>
                  <Typography variant="h3" style={{...webStyle.numberTexts}}>{this.getDashboardState('new_uploads_count')}</Typography>
                  {this.getDashboardState('new_uploads_count') ? <Typography style={{...webStyle.increase, ...webStyle.subtitleTexts}}>12% Increase</Typography> : ' '}
                </Paper>
              </Grid>
            </Grid>

            <Box mt={3} mb={6}>
              <Paper style={webStyle.card}>
                <div>
                    <Typography variant="subtitle1" style={{...webStyle.subtitleTexts}}>Recent</Typography>
                    <Typography variant="h5" style={{...webStyle.titleTexts}}>Transactions</Typography>
                </div>
                <CustomDivider variant="middle" />
                <div style={webStyle.transactionList}>
                  {this.state.arrayHolder.map((item: DashboardTransaction, index: number) => (
                    <Box key={index} style={webStyle.transaction}>
                      <Box display="flex" alignItems="center">
                        <Avatar style={webStyle.avatar} src={item.profile_photo} />
                        <Box>
                          <Typography>{item.name}</Typography>
                          <Typography variant="body2" style={{...webStyle.subtitleTexts, ...webStyle.transactionSubTitle}}>{item.location}</Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography variant="h6" style={webStyle.transactionNumber}>${item.amount || 0}</Typography>
                        <Typography variant="body2" style={{...webStyle.subtitleTexts, ...webStyle.transactionSubTitle}}>{item.date}</Typography>
                      </Box>
                    </Box>
                  ))}
                  {!this.state.arrayHolder?.length && (
                    <Box key={'no-data'} style={{...webStyle.transaction, padding: 0, justifyContent: 'center'}}>
                      <Box display="flex" alignItems="center">
                        <Typography variant="subtitle1" style={{color: '#94A3B8'}}>{"No recent transactions"}</Typography>
                      </Box>
                    </Box>
                  )}
                </div>
              </Paper>
            </Box>
          </div>
        </HamburgerMenu>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomDivider = styled(Divider)({
  backgroundColor: '#FFF',
  height: '1px',
  margin: '10px 0'
});
const webStyle = {
  
  root: {
    padding: theme.spacing(2),
    background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
    color: '#fff',
    minHeight: '100vh',
    paddingBottom: '50px',
    marginTop: '20px',
  },
  card: {
    backgroundColor: '#000',
    color: '#fff',
    padding: theme.spacing(2),
    'text-align': 'left',
    borderRadius: '8px',
  },
  increase: {
    color: '#059669',
  },
  decrease: {
    color: 'red',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  transaction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
  },
  iconStyle: {
    color: '#CBD5E1',  width: 'auto', height: '100%'
  },
  iconButton: {
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: '#5D5D5D',
    borderRadius: '8px', 
    padding: 8,
  },
  iconButtonUpload: {
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: '#5D5D5D',
    borderRadius: '8px', 
    padding: 8,
    '& .MuiIconButton-label': {
      width: '22px', 
      height: '22px'
    }
  },
  subtitleTexts: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
  },
  transactionNumber: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 700,
    color: '#059669'
  },
  titleTexts: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
  },
  numberTexts: {
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: 700,
  },
  transactionList: {
    maxHeight: '300px',
    overflow: 'auto'
  },
  transactionSubTitle: {
    color: '#94A3B8'
  }
};

const MenuClick = styled(Box)({
  background: "#000",
  padding: "4px 8px 4px 12px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  height: "48px",
  boxSizing: "border-box",
  color: "#94A3B8",
  "& .MuiSvgIcon-root":{
    fontSize: "24px"
  },
  marginBottom: '20px',
  'box-shadow': '0px 8px 32px 0px #0000000F',
});
const StyledPopover = styled(Popover)({
  "& .MuiPopover-paper":{
    maxHeight: "calc(100% - 220px)",
    width: "100%",
  },
  "& .regionsList":{
    padding: 0,
    display: "flex",
    flexDirection: "column",
    listStyle: "none",
    marginTop: 0,
    marginBottom: 0,
    background: "#000",
  },
  "& .regionsListItem":{
    height: "48px",
    padding: "12px 16px",
    background: "#000",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#FFF",
    boxSizing: "border-box"
  },
  "& .regionsListItem:hover":{
    background: "#373737",
    color: "#FFF",
  }
})

const CustomIconButton = styled(IconButton)({
  ...webStyle.iconButtonUpload,
  '& .MuiIconButton-label': {
    width: '22px', 
    height: '22px'
  }
})
// Customizable Area End
